<template>
  <div>
    <van-nav-bar title="编辑项目" left-text="返回" @click-left="back" fixed left-arrow />
    <div class="content">
        <van-form ref="projectSubmit">
        <van-field v-model="projectDetail.projectCode" :label="user.companyId == '7030' ? '项目令号' : '项目编号'" :disabled="!editCodeAndName"></van-field>
        <van-field v-model="projectDetail.projectName" label="项目名称" :disabled="!editCodeAndName"></van-field>
        <!-- 主项目 -->
        <van-field label="主项目" @click="mainProjectShow = true" readonly clickable v-if="user.timeType.mainProjectState == '1'">
            <template #input>
                <span>{{projectDetail.projectMainName}}</span>
            </template>
        </van-field>
            <van-popup v-model="mainProjectShow" position="bottom">
                <van-picker
                show-toolbar
                value-key="name"
                :columns="mainProjectList"
                @confirm="mainProjectConfirm"
                @cancel="mainProjectShow = false"
                ></van-picker>
            </van-popup>
        <!-- 项目分类 -->
        <van-field label="项目分类" @click="categoryShow = true" readonly clickable v-if="user.timeType.mainProjectState != '1'">
            <template #input>
                <span>{{projectDetail.categoryName}}</span>
            </template>
        </van-field>
            <van-popup v-model="categoryShow" position="bottom">
                <van-picker
                show-toolbar
                value-key="name"
                :columns="categoryList"
                @confirm="categoryConfirm"
                @cancel="categoryShow = false"
                ></van-picker>
            </van-popup>
        <!-- 项目描述 -->
        <van-field v-model="projectDetail.projectDesc" label="项目描述"></van-field>
        <!-- 项目类型 -->
        <van-field label="项目类型" @click="publicShow = true" readonly clickable>
            <template #input>
                <span>{{publicList[projectDetail.isPublic]}}</span>
            </template>
        </van-field>
            <van-popup v-model="publicShow" position="bottom">
                <van-picker
                show-toolbar
                :columns="publicList"
                @confirm="publicConfirm"
                @cancel="publicShow = false"
                ></van-picker>
            </van-popup>
        <!-- 项目级别 -->
        <van-field label="项目级别" @click="levelShow = true" readonly clickable>
            <template #input>
                <span>{{projectDetail.levelLabel}}</span>
            </template>
        </van-field>
            <van-popup v-model="levelShow" position="bottom">
                <van-picker
                show-toolbar
                value-key="label"
                :columns="levelList"
                @confirm="levelConfirm"
                @cancel="levelShow = false"
                ></van-picker>
            </van-popup>
        <!-- 日报审核人 -->
        <van-field label="日报审核人"  readonly clickable @click="dailyReviewFlg = true" v-if="user.timeType.reportAuditType == 0 || user.timeType.reportAuditType == 4">
            <template #input>
                <span v-if="projectDetail.auditorList.length > 0">
                    <span v-for="(items, indexs) in projectDetail.auditorList" :key="indexs">
                        <span v-if="user.userNameNeedTranslate != '1'">{{items.auditorName}}</span>
                        <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='items.auditorName'></TranslationOpenDataText></span>
                        <span v-if="projectDetail.auditorList.length - 1 > indexs">,</span>
                    </span>
                </span>
                <span v-else>-</span>
            </template>
        </van-field>
            <van-popup v-model="dailyReviewFlg" position="bottom" :style="{ height: '80%' }">
                <van-search v-model="userName" placeholder="输入员工姓名搜索" @search="onSearch" v-if="user.userNameNeedTranslate != '1'"></van-search>
                <div style="minHeight:300px;">
                <template v-if="user.userNameNeedTranslate == '1'">
                    <van-checkbox class="userCheckbox" v-for="(item) in dailyUserList" :key="item.id" v-model="item.isChecked" ><TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText></van-checkbox>
                </template>
                <template v-else>
                    <van-checkbox class="userCheckbox" v-for="(item) in dailyUserList" :key="item.id" v-model="item.isChecked" >{{item.name}}</van-checkbox>
                </template>
                <van-button style="width:100%;position: -webkit-sticky;position: sticky;bottom: 0;" @click="auditorNameConfirm(),dailyReviewFlg=false">确定</van-button>
                </div>
            </van-popup>
        <!-- 参与人 -->
        <!-- <van-button @click="test">test</van-button> -->
        <van-field label="全部参与人" @click="userNamesShow = true" readonly clickable v-show="projectDetail.isPublic == 0">
            <template #input>
                <span v-if="!projectDetail.userNames"></span>
                <span v-else-if="user.userNameNeedTranslate == '1'">
                    <span v-for="item,index in projectDetail.userNames.split(',')" :key="item">
                        <TranslationOpenDataText type='userName' :openid='item'></TranslationOpenDataText>
                        <span v-if="index != (projectDetail.userNames.split(',').length - 1)">,</span>
                    </span>
                </span>
                <span v-else>{{projectDetail.userNames}}</span>
            </template>
        </van-field>
            <van-popup v-model="userNamesShow" position="bottom" :style="{ height: '80%' }">
                <van-search v-model="userName" placeholder="输入员工姓名搜索" @search="onSearch" v-if="user.userNameNeedTranslate != '1'"></van-search>
                <div style="minHeight:300px;">
                <template v-if="user.userNameNeedTranslate == '1'">
                    <van-checkbox class="userCheckbox" v-for="(item) in userList" :key="item.id" v-model="item.isChecked" ><TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText></van-checkbox>
                </template>
                <template v-else>
                    <van-checkbox class="userCheckbox" v-for="(item) in userList" :key="item.id" v-model="item.isChecked" >{{item.name}}</van-checkbox>
                </template>
                <van-button style="width:100%;position: -webkit-sticky;position: sticky;bottom: 0;" @click="userNamesConfirm();userNamesShow=false">确定</van-button>
                </div>
            </van-popup>
        <!-- 项目经理 -->
        <van-field label="项目经理" @click="editProjectMan ? inchargerShow = true : ''" readonly clickable :disabled="!editProjectMan">
            <template #input>
                <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='projectDetail.inchargerName'></TranslationOpenDataText></span>
                <span v-else>{{projectDetail.inchargerName}}</span>
            </template>
        </van-field>
            <van-popup v-model="inchargerShow" position="bottom">
                <van-picker
                show-toolbar
                value-key="name"
                :columns="inchargerList"
                @confirm="inchargerConfirm"
                @cancel="inchargerShow = false"
                >
                <template #option="item">
                    <span v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText></span>
                    <span v-else>{{item.name}}</span>
                </template>
                </van-picker>
            </van-popup>
        <!-- 开始日期 -->
        <van-field label="计划开始日期" @click="startDateShow = true" readonly clickable>
            <template #input>
                <span>{{projectDetail.planStartDate}}</span>
            </template>
        </van-field>
            <van-popup v-model="startDateShow" position="bottom">
                <van-datetime-picker
                v-model="currentStartDate"
                type="date"
                title="选择年月日"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="startDateConfirm"
                @cancel="startDateShow = false"
                />
            </van-popup>
        <!-- 结束日期 -->
        <van-field label="计划结束日期" @click="endDateShow = true" readonly clickable>
            <template #input>
                <span>{{projectDetail.planEndDate}}</span>
            </template>
        </van-field>
            <van-popup v-model="endDateShow" position="bottom">
                <van-datetime-picker
                v-model="currentEndDate"
                type="date"
                title="选择年月日"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="endDateConfirm"
                @cancel="endDateShow = false"
                />
            </van-popup>
        </van-form>


        <div class="form_btn" style="position: fixed; bottom: 0px; width: 100%">
            <div style="padding-bottom: 10px">
                <van-button square block type="info" @click="deleteProject()" style="width: 50%; float: left">删除</van-button>
                <van-button square block type="danger" @click="submitProject" style="width: 50%; float: left">保存</van-button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            user: JSON.parse(localStorage.userInfo),
            projectId: JSON.parse(sessionStorage.projectId),
            minDate: new Date(2020,0,1),
            maxDate: new Date(2025,11,31),
            projectDetail: {
                auditorList: []
            },
            editCodeAndName: false,

            mainProjectList: [],
            mainProjectShow: false,
            categoryList: [],
            categoryShow: false,
            publicList: ['正式项目','非项目'],
            publicShow: false,
            levelList: [],
            levelShow: false,
            userName: null,
            allUserList: [],
            userList: [],
            userNamesShow: false,
            inchargerList: [],
            inchargerShow: false,
            currentStartDate: new Date(),
            startDateShow: false,
            currentEndDate: new Date(),
            endDateShow: false,
            dailyUserList: [],
            dailyReviewFlg: false,
            editProjectMan: false
        }
    },
    mounted() {
        this.getProjectDetail()
        if(this.user.timeType.mainProjectState == '1'){
            this.getMainProjectList()
        }else{
            this.getCategoryList()
        }
    },
    methods: {
        back() {
            history.back();
        },
        formatDate(date) {
            let mon = date.getMonth() + 1
            return `${date.getFullYear()}-${mon<10?'0'+mon:mon}-${date.getDate()<10?'0'+date.getDate():date.getDate()}`;
        },
        test(){
            console.log(this.projectDetail);
        },

        mainProjectConfirm(value,key){
            this.projectDetail.projectMainId = value.id
            this.projectDetail.projectMainName = value.name
            this.categoryShow = false
        },
        categoryConfirm(value,key){
            this.projectDetail.category = value.id
            this.projectDetail.categoryName = value.name
            this.categoryShow = false
        },
        publicConfirm(value,key){
            this.projectDetail.isPublic = key
            this.publicShow = false
        },
        levelConfirm(value,key){
            this.projectDetail.level = value.id
            this.projectDetail.levelLabel = value.label
            this.levelShow = false
        },
        onSearch(val) {
            console.log(val);
            this.userList = [];
            this.allUserList.forEach(u=>{if (u.name.startsWith(val)) {
                this.userList.push(u);
            }})
        },
        userNamesConfirm(){
            let that = this;
            that.inchargerList = [];
            that.projectDetail.userId = [];
            let userNames = ''
            this.userList.filter(u=>u.isChecked).forEach(u=>{
                userNames+=(u.name+',');
                that.projectDetail.userId.push(u.id);
                that.inchargerList.push(u);
            });
            if (userNames.length > 0) {
                userNames = userNames.substring(0, userNames.length-1);
            }
            this.$set(this.projectDetail,'userNames',userNames)
            this.integrationreAuidType()
        },
        auditorNameConfirm() {
            let auditorIdList = []
            let auditorList = []
            this.dailyUserList.filter(u=>u.isChecked).forEach(u=>{
                auditorIdList.push(u.id)
                let obj = {}
                obj.auditorName = u.name
                obj.auditorId = u.id
                auditorList.push(obj)
            });
            this.projectDetail.auditUserIds = auditorIdList
            this.projectDetail.auditorList = auditorList
        },
        integrationreAuidType() {
            this.dailyUserList = []
            this.userList.filter(u=>u.isChecked).forEach(u=>{
                let obj = {}
                obj.name = u.name
                obj.id = u.id
                obj.isChecked = false
                this.dailyUserList.push(obj)
            });
            let list = this.dailyUserList
            for(var i in list) {
                let length = this.projectDetail.auditorList.filter(u => u.auditorId == list[i].id).length
                if(length){
                    list[i].isChecked = true
                }else{
                    list[i].isChecked = false
                }
            }
        },
        inchargerConfirm(value,key){
            this.projectDetail.inchargerId = value.id
            this.projectDetail.inchargerName = value.name
            this.inchargerShow = false
        },
        startDateConfirm(value){
            this.projectDetail.planStartDate = this.formatDate(value)
            this.startDateShow = false
        },
        endDateConfirm(value){
            this.projectDetail.planEndDate = this.formatDate(value)
            this.endDateShow = false
        },


        submitProject(){
            // this.$refs.projectSubmit.validate().then(()=>{
                let formData = new FormData();
                formData.append("id", this.projectDetail.id);
                formData.append("name", this.projectDetail.projectName);
                formData.append("code", this.projectDetail.projectCode ? this.projectDetail.projectCode : '');
                if(this.projectDetail.projectMainId && this.user.timeType.mainProjectState == '1') {
                    formData.append("projectMainId", this.addForm.projectMainId);
                }
                if(this.projectDetail.category) {
                    formData.append("category", this.projectDetail.category);
                }
                formData.append("projectDesc", this.projectDetail.projectDesc ? this.projectDetail.projectDesc : '');
                formData.append("isPublic", this.projectDetail.isPublic);
                if(this.projectDetail.userId.length != 0 && this.projectDetail.isPublic == 0) {
                    for(let i in this.projectDetail.userId) {
                        formData.append("userId", this.projectDetail.userId[i]);
                    }
                }
                if(this.projectDetail.inchargerId) {
                    formData.append("inchargerId", this.projectDetail.inchargerId);
                }
                if(this.projectDetail.planStartDate) {
                    formData.append("planStartDate", this.projectDetail.planStartDate);
                }
                if(this.projectDetail.planEndDate) {
                    formData.append("planEndDate", this.projectDetail.planEndDate);
                }
                if (this.projectDetail.auditUserIds) {
                    formData.append("auditUserIds", JSON.stringify(this.projectDetail.auditUserIds));
                }
                
                // console.log(this.projectDetail)
                // return
                this.$axios.post("/project/editProject", formData)
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.success('修改成功')
                        this.back()
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();console.log(err)});
            // }).catch(()=>{return})
        },
        deleteProject(){
            this.$dialog.confirm({
                    title: '删除项目',
                    message: '确定要删除项目'+this.projectDetail.projectName+'吗?'
                }).then(() => {
                    const toast = this.$toast.loading({
                        forbidClick: true,
                        duration: 0
                    });
                    this.$axios.post("/project/deleteProject", {id: this.projectDetail.id})
                    .then(res => {
                        if(res.code == "ok") {
                            this.$toast.clear();
                            this.$toast.success('删除成功');
                            this.$router.push('/project');
                        } else if(res.code == 'reconfirm'){
                            this.$toast.clear();
                            this.forceDelete(res.msg)
                        } else {
                            this.$toast.clear();
                            this.$toast.fail(res.msg);
                        }
                    }).catch(err=> {this.$toast.clear();});
                }).catch(() => {});
        },
        forceDelete(msg){
            this.$dialog.confirm({
                    title: '删除项目',
                    message: msg
                }).then(() => {
                    const toast = this.$toast.loading({
                        forbidClick: true,
                        duration: 0
                    });
                    this.$axios.post("/project/deleteProject", {id: this.projectDetail.id, force: 1})
                    .then(res => {
                        if(res.code == "ok") {
                            this.$toast.clear();
                            this.$toast.success('删除成功');
                            this.back();
                        } else {
                            this.$toast.clear();
                            this.$toast.fail(res.msg);
                        }
                    }).catch(err=> {this.$toast.clear();});
                }).catch(() => {});
        },
        

        getProjectDetail(){
            console.log('发起了请求')
            this.$axios.post("/project/detail", {id: this.projectId})
            .then(res => {
                if(res.code == "ok") {
                    console.log('成功')
                    this.projectDetail = res.data
                    let userNames = ''
                    let userId = []
                    for(let i in this.user.functionList){
                        if(this.user.functionList[i].name == '编辑负责项目编码和名称' || this.user.functionList[i].name == '管理全部项目'){
                            this.editCodeAndName = true
                        }
                        if(this.user.functionList[i].name == '管理全部项目' || this.user.id == res.data.creatorId){
                            this.editProjectMan = true
                        }
                    }

                    for(let i in res.data.participationList){
                        if(res.data.participationList[i].id && res.data.participationList[i].id != 'null'){
                            userNames += res.data.participationList[i].name + ','
                            userId.push(res.data.participationList[i].id)
                            this.inchargerList.push(res.data.participationList[i])
                        }
                    }
                    this.$set(this.projectDetail,'userNames',userNames ? userNames.substring(0,userNames.length - 1) : '')
                    this.$set(this.projectDetail,'userId',userId)
                    if(res.data.planStartDate){
                        this.currentStartDate = new Date(res.data.planStartDate)
                    }
                    if(res.data.planEndDate){
                        this.currentEndDate = new Date(res.data.planEndDate)
                    }
                    this.getUserList()
                    if(this.user.timeType.projectLevelState == 1){
                        this.getLevelList()
                    }else{
                        this.levelList = [
                            {label: '正常' ,id: 1},
                            {label: '紧急' ,id: 2},
                            {label: '重要' ,id: 3},
                            {label: '重要且紧急' ,id: 4},
                        ]
                        this.projectDetail.levelLabel = this.levelList[this.projectDetail.level - 1].label
                    }

                    if(this.user.id == res.data.creatorId){
                        this.editCodeAndName = true
                        return
                    }
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getMainProjectList(){
            this.$axios.post("/project-main/list", {})
            .then(res => {
                if(res.code == "ok") {
                    this.mainProjectList = res.data
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getCategoryList(){
            this.$axios.get("/project-category/list", {})
            .then(res => {
                if(res.code == "ok") {
                    this.categoryList = res.data
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getLevelList(){
            this.$axios.get("/project-level/list", {})
            .then(res => {
                if(res.code == "ok") {
                    this.levelList = res.data
                    let list = this.levelList.filter(u => u.id == this.projectDetail.level)
                    this.projectDetail.levelLabel = list.length != 0 ? list[0].label : ''
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getUserList(){
            this.$axios.post("/user/getEmployeeList", {departmentId: -1,pageIndex: 1,pageSize: -1})
            .then(res => {
                if(res.code == "ok") {
                    let list = res.data.records
                    for (let i in list) {
                        let length = this.projectDetail.participationList.filter(u => u.id == list[i].id).length
                        if(length){
                            list[i].isChecked = true
                        }else{
                            list[i].isChecked = false
                        }
                    }
                    this.allUserList = list;
                    this.userList = list;
                    this.integrationreAuidType()
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        }
    },
}
</script>

<style lang="less" scoped>
.content{
    margin-top: 46px;
    overflow: auto;
    .userCheckbox {
        padding: 10px;
    }
}
</style>